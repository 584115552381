<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="12" sm="5" md="5">
          <v-treeview
            :active.sync="active"
            v-model="active"
            :items="items"
            :open.sync="open"
            :item-key="'id'"
            :item-text="'label'"
            activatable
            :open-all="true"
            color="primary"
            transition
            @update:open="onOpen"
            @update:active="onActive"
            :return-object="true"
            selection-type="independent"
            :key="kt"
          >
            <template v-slot:label="{ item }">
              <v-chip :color="item.ChildCount > 0 ? 'blue' : item.color">
                <v-avatar
                  left
                  class="black"
                  v-if="
                    item.type_id == 2 && item.ChildCount == 0 && item.NbRack > 0
                  "
                >
                  {{ item.NbRack }}
                </v-avatar>
                {{ item.prop_name ? item.prop_name + ":" : "" }}
                {{ item.label }}
              </v-chip>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <template>
            <v-btn
              fab
              small
              class="mx-1"
              :disabled="racks_list.length > 0 || nbproduit > 0"
              color="indigo"
              @click="new_structure"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="mx-1"
              color="indigo"
              @click="delete_structure(selected)"
              :disabled="
                selected
                  ? selected.children.length != 0 ||
                    selected.deletable == false ||
                    nbproduit > 0 ||
                    ChildCount > 0 ||
                    selected.Nbmachine > 0 ||
                    selected.id == 1 ||
                    racks_list.length > 0
                  : true
              "
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="mx-1"
              color="indigo"
              @click="edit_structure(selected)"
              :disabled="!selected || (selected ? selected.id == 1 : true)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-col>

        <v-divider vertical></v-divider>
        <v-col class="pa-6" cols="5" sm="5" md="5" v-if="structtype == 's'">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            no selection
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <h3 class="blue--text headline mb-2">Libelle :</h3>
              <p class="headline mb-2">{{ selected.label }}</p>

              <div>
                <p class="blue--text mb-2">Description :</p>
                {{ selected.description }}
              </div>
              <div>
                <p class="mb-2">Responsable : {{ selected.responsable }}</p>
              </div>
              <div>
                <p class="mb-2">Type : {{ selected.type }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pa-6" cols="5" sm="5" md="5" v-if="structtype == 'd'">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="
                ChildCount == 0 && (selected ? selected.type_id == 2 : true)
              "
            >
              <listitems
                :list="racks_list"
                :selitem="selitem"
                :title="rack_name"
                :qDelete="Qrackdelete"
                :Get_suf="'Rack'"
                :headers="rack_headers"
                :filename="'Racks'"
                :sheetname="'Racks'"
                :showedit="false"
                :showstd="false"
                @open="RackOpen"
                @rowselect="RackSelect"
                :key="kr"
                :add="true"
                :add_disable="!selected || nbproduit == 0"
                :del_disable="boxs_list.length > 0"
                :del="true"
                @delete="refresh"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="rack.id">
              <v-row v-for="(level, index) in reverseItems" :key="index" dense>
                <v-col cols="12" sm="3" md="2" class="mt-1">
                  <v-chip class="green">
                    {{ reverseItems.length - index }}
                  </v-chip>
                </v-col>
                <v-col cols="12" sm="10" md="10">
                  <v-chip
                    class="ma-1"
                    v-for="(box, index2) in level"
                    :key="index2"
                    width="20"
                    :color="box.stock_cmpt > 0 ? 'blue' : 'gray'"
                    @click="BoxOpen(box)"
                  >
                    {{ box.ref ? box.ref : box.code }}
                  </v-chip>
                </v-col>
              </v-row>
              <!-- <listitems
                :list="boxs_list"
                :title="box_name"
                :qDelete="Qboxdelete"
                :qCreate="Qboxcreate"
                :qUpdate="Qboxupdate"
                :Get_suf="'Box'"
                :headers="box_headers"
                :filename="'Boxs'"
                :sheetname="'Boxs'"
                :showedit="true"
                :showstd="true"
                :key="kb"
                :add="true"
                :add_disable="selected ? selected.id > 0 : false"
                :del="true"
              >
              </listitems> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <structureform
      :item="editeditem"
      :items="list"
      :list="list"
      :agents="agents"
      :departements="departements"
      :key="cf"
      :showForm="!isClosed"
      @close="closeForm"
      :modify="true"
      @change="update"
      @add="add"
      :structtype="structtype"
    >
    </structureform>
    <rackform
      :item="rack"
      :items="racks_list"
      :key="krf"
      :showForm="!isRackClosed"
      @close="closeRackForm"
      :depot="selected"
      :range_name="range_name"
      :rack_name="rack_name"
      :box_name="box_name"
      @add="rack_add"
      @change="kr++"
    >
    </rackform>
    <boxform
      :item="box"
      :items="boxs_list"
      :key="kbf"
      :showForm="!isBoxClosed"
      @close="closeBoxForm"
      :rack="rack"
      :range_name="range_name"
      :rack_name="rack_name"
      :box_name="box_name"
    >
    </boxform>
    <confirmdialog ref="confirm" />
  </v-container>
</template>

<script>
function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
}

import STRUCTURES from "../graphql/Structure/STRUCTURES.gql";

import DELETE_RACK from "../graphql/Depot/DELETE_RACK.gql";
import CREATE_BOX from "../graphql/Depot/CREATE_BOX.gql";
import UPDATE_BOX from "../graphql/Depot/UPDATE_BOX.gql";
import DELETE_BOX from "../graphql/Depot/DELETE_BOX.gql";
import RACKS from "../graphql/Depot/RACKS.gql";
import DELETE_STRUCTURE from "../graphql/Structure/DELETE_STRUCTURE.gql";
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    node.index = i;
    if (node.fk_parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.fk_parent]])
        list[map[node.fk_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    structureform: () => import("../components/StructureForm.vue"),
    rackform: () => import("../components/RackForm.vue"),
    boxform: () => import("../components/BoxForm.vue"),
  },
  props: {
    structtype: String,
  },
  data() {
    return {
      kt: 100,
      kr: 200,
      kb: 300,
      krf: 400,
      kbf: 500,
      cf: 0,
      nbproduit: 0,
      ChildCount: 0,
      rack_name: "Rack",
      box_name: "Box",
      range_name: "Niveau",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      tabs: null,
      box_headers: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
        },
        {
          text: "Réference",
          value: "ref",
          selected: true,
          rules: [(v) => !!v || "Réference obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Rack",
          value: "rack_id",
          hiden: true,
          edit: true,
          default: "",
        },
      ],
      rack_headers: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Code",
          value: "code",
          slot: "href",
          selected: true,
        },
        {
          text: "Réference",
          value: "ref",
          selected: true,
        },
        {
          text: "Niveaux",
          value: "levels",
          selected: true,
        },
      ],
      open: [],
      opened: [],
      opened_save: [],
      active: [],
      active_save: [],
      list: [],
      items: [],
      agents: [],
      departements: [],
      racks: [],
      boxs: [],
      boxs2: [],
      editeditem: {},
      qDelete: {},
      rack: {},
      box: {},
      selitem: {},
      isClosed: true,
      isRackClosed: true,
      isBoxClosed: true,
      color: "",
    };
  },
  watch: {
    structtype: {
      handler() {
        this.get_data();
      },
      deep: true,
    },
  },
  mounted() {
    this.qDelete = DELETE_STRUCTURE;

    this.get_data();
  },
  computed: {
    reverseItems() {
      return this.boxs2.slice().reverse();
    },
    racks_list() {
      let list = [];
      if (this.racks && this.selected)
        list = this.racks.filter((elm) => elm.depot_id == this.selected.id);
      return list;
    },
    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter((elm) => elm.rack_id == this.rack.id);
      return list;
    },
    Qrackdelete() {
      return DELETE_RACK;
    },
    Qboxcreate() {
      return CREATE_BOX;
    },
    Qboxupdate() {
      return UPDATE_BOX;
    },
    Qboxdelete() {
      return DELETE_BOX;
    },

    swatchStyle() {
      return {
        backgroundColor: this.selected.color,
        height: "30px",
        width: "30px",
        borderRadius: "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    selected() {
      if (!this.active.length) return undefined;
      // const id = this.active[0].id;
      return this.active[0];
    },
  },
  methods: {
    rack_add(rack, boxs) {
      boxs.forEach((element) => {
        this.boxs.push(element);
      });
      this.selitem = rack;
      this.kr++;
    },
    refresh(item) {
      this.racks_list.splice(item.index, 1);
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    RackSelect(item) {
      this.rack = item;
      this.selitem = item;
      this.box_headers[3].default = this.rack.id;
      this.kb++;
      this.boxs2 = [];
      for (let index = 0; index < this.rack.levels; index++) {
        let l = this.boxs_list.filter((elm) => elm.level_id == index + 1);
        this.boxs2[index] = [];
        for (let i = 0; i < l.length; i++) {
          const element = l[i];
          this.boxs2[index].push(element);
        }
      }
    },
    async get_data() {
      this.list = [];
      this.active = [];
      this.active_save = [];
      this.items = [];
      if (this.structtype == "d") {
        r = await this.requette(RACKS);
        if (r) {
          this.racks = r.racks;
          this.boxs = r.boxs;
        }
      }
      let r = await this.requette(STRUCTURES);
      if (r) {
        this.list = r.structures.filter((elm) =>
          this.structtype == "s" ? elm.type_id != 2 : elm.type_id <= 2
        );
        this.departements = r.departements;
        this.agents = r.agents;
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].deletable = this.list[i].agentcount == 0;
        }
        this.items = list_to_tree(this.list);
        this.open = this.opened_save;
        this.active = this.active_save;
        this.kt++;
      }
    },
    add(item) {
      this.list.push(item);
      this.items = list_to_tree(this.list);
      if (this.list[this.editeditem.index])
        this.list[this.editeditem.index].locked = false;
      this.kt++;
    },
    update(item) {
      this.list.splice(item.index, 1, item);
      this.items = list_to_tree(this.list);
      if (this.list[this.editeditem.index])
        this.list[this.editeditem.index].locked = false;
      this.kt++;
    },
    onActive() {
      if (this.active.length > 0) {
        this.rack = {};
        this.boxs2 = [];
        this.nbproduit = this.active[0].NbProduit;
        this.ChildCount = this.active[0].ChildCount;
        this.rack_name = this.active[0].rack ? this.active[0].rack : "Rack";
        this.box_name = this.active[0].box ? this.active[0].box : "Box";
        this.range_name = this.active[0].range
          ? this.active[0].range
          : "Niveau";
        this.rack_headers[3].text = this.active[0].range
          ? this.active[0].range
          : "Niveau";
      }

      this.active_save = this.active;
      this.kr++;
      this.kb++;
    },
    onOpen(items) {
      var onlyInA = items.filter(comparer(this.opened));
      var onlyInB = this.opened.filter(comparer(items));

      let newnode = onlyInA.concat(onlyInB);
      if (newnode.length > 0) {
        let i = items.findIndex(
          (elm) =>
            elm.id != newnode[0].id && elm.fk_parent == newnode[0].fk_parent
        );
        if (i >= 0) items.splice(i, 1);
        this.opened = [];
        this.open.forEach((element) => {
          this.opened.push(element);
          this.opened_save.push(element);
        });
        this.open = items;
      }
    },
    closeForm() {
      this.isClosed = true;
    },
    closeRackForm() {
      this.isRackClosed = true;
    },
    RackOpen(item) {
      this.rack = item;
      this.isRackClosed = false;
      this.krf++;
    },
    closeBoxForm() {
      this.isBoxClosed = true;
    },
    BoxOpen(item) {
      this.box = item;
      this.isBoxClosed = false;
      this.kbf++;
    },
    new_structure() {
      this.cf++;
      this.editeditem = {
        id: -1,
        fk_parent: this.selected ? this.selected.id : 0,
        parent_type: this.selected ? this.selected.type_id : 0,
        color: "",
        disabled: false,
      };
      if (this.selected)
        if (!this.open.find((elm) => elm.id == this.selected.id))
          this.open.push(this.selected);
      this.isClosed = false;
    },
    edit_structure(item) {
      this.cf++;
      this.editeditem = this.list[item.index];
      this.list[item.index].locked = true;
      this.isClosed = false;
    },

    async delete_structure(item) {
      this.editeditem = item;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        this.active = [];
        this.list.splice(item.index, 1);

        this.$apollo
          .mutate({
            mutation: this.qDelete,
            variables: {
              id: item.id,
            },
          })
          .then(() => {
            this.items = list_to_tree(this.list);
            this.kt++;
          })
          .catch((error) => {
            this.snackbar_color = "error";
            this.snackbar_text = error.message;
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
